import { apiGecall, apiDatePoint } from '@/api/api.js'
export default {
    components: {
    },
    data() {
        return {
            loading: true,
            page: 1,
            pageCount: 0,
            examinationList: []
        }
    },
    mounted() {
        this.getGecall()
    },
    methods: {
        // 获取内容
        getGecall() {
            let paramsBase = {
                page: this.page,
            }
            apiGecall(paramsBase).then((res) => {
                let _this = this
                let valueList = res.data.list
                valueList.forEach(element => {
                    element.page = 1
                    element.loading=false
                    _this.examinationList.push(element)
                });
                this.pageCount = res.data.count_page
                this.loading = false
            })
        },
        clickAddExamination() {
            this.$router.push('/AddExamination')
        },
        clickSeeMorePoint(item) {
            item.page++
            item.loading = true
            let paramsBase = {
                date: item.test_time,
                from: 'exp',
                page: item.page,
            }
            apiDatePoint(paramsBase).then((res) => {
                if (res.data.gre_list.length > 0) {
                    let list =res.data.gre_list
                    list.forEach(element=>{
                        item.child.push(element)
                    })
                }else{
                    item.page=-1
                }
                setTimeout(() => {
                    item.loading = false
                }, 1000);
            })
        },
        seeMoreExamination() {
            this.page++
            this.getGecall()
        }
    },
}